import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import TeamCard from "./TeamCard";
import CBHeader from "./CBHeader";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const teamData = [
  {
    id: 1,
    name: "Taye Oyeleye",
    post: "CEO | Co-founder",
    imgSrc: "taye1.jpg",
    linkedinURL: "https://www.linkedin.com/in/taye-oyeleye/",
    bio: `The co-brain behind CyberBrace System & Technology, a highly skilled and professional software engineer with expertise in web application
    development, strong proficiency in technologies such as ReactJS, NodeJS, ExpressJS, Go, and
    MongoDB, and extensive experience with Amazon Web Services (AWS). Dedicated to delivering
    innovative and scalable solutions that drive business growth, enhance user experiences, and meet client requirements across diverse industries.`,
  },
  {
    id: 2,
    name: "Kehinde Oyeleye",
    post: "CTO | Co-founder",
    imgSrc: "kenny1.jpg",
    linkedinURL: "https://www.linkedin.com/in/kehinde-oyeleye/",
    bio: `The co-brain behind CyberBrace System & Technology, a highly skilled and professional software engineer with expertise in web application
    development, strong proficiency in technologies such as ReactJS, NodeJS, ExpressJS, Go, and
    MongoDB, and extensive experience with Amazon Web Services (AWS). Dedicated to delivering
    innovative and scalable solutions that drive business growth, enhance user experiences, and meet client requirements across diverse industries.`,
  },
  {
    id: 3,
    name: "Okanmiyo Titilayo",
    post: "IT Engineer",
    imgSrc: "miyo.JPG",
    linkedinURL: "https://www.linkedin.com/in/titilayo-okanmiyo-3a5a27141/",
    bio: "A certified network engineer, ITIL professional in computer infrastructure facilities system management with wide range knowledge experience in delivering technical support and consultancy to our various client organizations for proper management of their IT structure and networking devices.    ",
  },
  {
    id: 4,
    name: "Akintola Adebule",
    post: "Frontend-Developer",
    imgSrc: "kito.png",
    linkedinURL: "https://www.linkedin.com/",
    bio: "A dedicated frontend developer who specializes in creating innovative, scalable solutions that enhance user experiences and drive business growth. With expertise across diverse industries, focused on meeting client requirements through cutting-edge design and efficient code, I ensure seamless, user-friendly interfaces that deliver outstanding results.   ",
  },
  {
    id: 5,
    name: "Israel Asolo",
    post: "Frontend-Developer",
    imgSrc: "asolo.png",
    linkedinURL: "https://www.linkedin.com/in/asolo-olakunle-947941245/",
    bio: "A skilled frontend developer committed to crafting innovative, scalable solutions that boost user experiences and foster business growth. Experienced across various industries, prioritising client needs with advanced design and efficient coding, delivering seamless, intuitive interfaces that achieve exceptional outcomes.   ",
  },
];

const Team = () => {
  const [selectedTeam, setSelectedTeam] = React.useState(null);

  const handleTeamClick = (data) => {
    setSelectedTeam({
      id: data.id,
      name: data.name,
      bio: data.bio,
    });
  };

  React.useEffect(() => {
    console.log(selectedTeam);
  }, [selectedTeam]);
  return (
    <React.Fragment>
      <div className="header-container" id="team">
        <div className=" ">
          <CBHeader
            header={"Our Team"}
            caption={`
            Meet our skilled tech team driving innovation, ensuring top-tier solutions, and delivering outstanding results for your business.
            `}
          />
        </div>
      </div>

      <div className="slider-container">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={true}
          autoPlaySpeed={3000}
          // centerMode={true} // Shows the next items and previous items partially.
          // keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={3000}
          // containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          // deviceType={this.props.deviceType}
          // dotListClass="custom-dot-list-style"
          // itemClass="carousel-item-padding-40-px"
        >
          {teamData &&
            teamData.map((data, index) => (
              <TeamCard
                key={index}
                name={data.name}
                post={data.post}
                bio={data.bio}
                linkedinURL={data.linkedinURL}
                onClick={(data) => handleTeamClick(data)}
                imgSrc={`images/${data.imgSrc}`}
              />
            ))}
        </Carousel>
      </div>

      {selectedTeam && (
        <>
          <div
            className="modal fade show"
            tabIndex="-1"
            style={{ display: "block" }}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="back-drop-overlay"></div>

            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {selectedTeam.name}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setSelectedTeam(null)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">{selectedTeam.bio}</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      setSelectedTeam(null);
                    }}
                  >
                    Close
                  </button>
                  {/* <button type="button" className="btn btn-primary">
      Save changes
    </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Team;
