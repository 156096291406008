import React, { useEffect, useState } from "react";

const Navbar = () => {
  const [navbarBg, setNavbarBg] = useState("transparent");
  const [activeLink, setActiveLink] = useState("#home");

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 200) {
      setNavbarBg("#090524");
    } else {
      setNavbarBg("transparent");
    }

    // Get all section elements
    const sections = document.querySelectorAll("section");
    const scrollPosition = window.scrollY;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 70; // Adjust for navbar height
      const sectionHeight = section.offsetHeight;

      if (
        scrollPosition >= sectionTop &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        setActiveLink(`#${section.id}`);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(`Active link changed to: ${activeLink}`);
  }, [activeLink]);

  const handleClick = (id) => {
    setActiveLink(id);
  };

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg fixed-top animated fadeInDown navbar-transition"
        style={{ animationDelay: "0.5s", backgroundColor: navbarBg }}
      >
        <div className="navbar-header page-scroll">
          <a className="navbar-brand mt-3 nav-link page-scroll" href="/">
            <img src="images/cybe.png" alt="logo" width="200" height="60" />
          </a>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto me-5">
            <li className="nav-item">
              <a
                className={`nav-link page-scroll ${
                  activeLink === "#home" ? "active" : ""
                }`}
                href="#home"
                onClick={() => handleClick("#home")}
                style={{
                  color: activeLink === "#home" ? "#ffffff" : "lightgray",
                }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link page-scroll ${
                  activeLink === "#services" ? "active" : ""
                }`}
                href="#services"
                onClick={() => handleClick("#services")}
                style={{
                  color: activeLink === "#services" ? "#ffffff" : "lightgray",
                }}
              >
                Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link page-scroll ${
                  activeLink === "#team" ? "active" : ""
                }`}
                href="#team"
                onClick={() => handleClick("#team")}
                style={{
                  color: activeLink === "#team" ? "#ffffff" : "lightgray",
                }}
              >
                Our Team
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link page-scroll ${
                  activeLink === "#remark" ? "active" : ""
                }`}
                href="#remark"
                onClick={() => handleClick("#remark")}
                style={{
                  color: activeLink === "#remark" ? "#ffffff" : "lightgray",
                }}
              >
                Remarks
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link page-scroll ${
                  activeLink === "#contact" ? "active" : ""
                }`}
                href="#contact"
                onClick={() => handleClick("#contact")}
                style={{
                  color: activeLink === "#contact" ? "#ffffff" : "lightgray",
                }}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
