import React from "react";
import { ReactComponent as StarIcon } from "../../static/svg/star.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Testimony = ({ remarks }) => {
  return (
    <React.Fragment>
      <div className="">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          transitionDuration={3000}
          infinite={true}
          showDots={false}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {remarks.map((item, index) => (
            <div className="card mx-2 hCard" key={index}>
              <div className="card-body testy">
                <div className=" mt-3 mb-4">
                  <StarIcon className="starSize" />
                  <StarIcon className="starSize" />
                  <StarIcon className="starSize" />
                  <StarIcon className="starSize" />
                  <StarIcon className="starSize" />
                </div>
                <h4>{item.text}</h4>

                <div className="texty">
                  <img
                    src={item.img}
                    className="img-fluid shadow-lg"
                    alt={item.client}
                  />
                  <div className="pt-3">
                    <strong className="">{item.client}</strong>
                    <p className="">
                      {item.company}
                      {/* CEO - Jibeal Unique Company Ltd */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </React.Fragment>
  );
};

export default Testimony;
