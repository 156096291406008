import React from "react";
import { ReactComponent as LinkedinIcon } from "../../static/svg/linkedin.svg";

const TeamCard = (props) => {
  return (
    <React.Fragment>
      <div className="my-team">
        <div className="slider-container">
          <div className="slider-content">
            <div className="card-wrapper">
              <div className="cardozo">
                <div className="image-content">
                  <span className="overlay"></span>
                  <div className="card-image">
                    <img src={props.imgSrc} alt="" className="card-img" />
                  </div>
                </div>
                <div className="card-content">
                  <h2 className="name">
                    {props.name}
                    <a
                      className=" socials"
                      target="_blanc"
                      href={props.linkedinURL}
                    >
                      <LinkedinIcon />
                    </a>
                  </h2>
                  <h2 className="post">{props.post}</h2>
                  <p className="bio d-inline-block col-10 text-truncate">
                    {props.bio}
                  </p>
                  <button
                    onClick={() => props.onClick(props)}
                    type="button"
                    className="btn btn-outline-danger"
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamCard;
