import React from "react";

const Services = () => {
  return (
    <div
      className="container-fluid"
      style={{
        paddingTop: "70px",
        paddingBottom: "70px",
        height: "auto",
        background: `url('images/mobile.jpg')`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      {/* <div className="row">
        <div
          className="container-fluid"
          style={{
            height: "auto",
            background: `url('images/design.jpg')`,
            backgroundSize: "100% 100%",
            width: "100% auto",
            marginTop: "-70px",
          }}
        >
          <div className="divider mb-5" style={{ marginTop: "100px" }}></div>
          <h3
            className="text-center mt-3"
            style={{
              color: "#057e8c",
              fontSize: "35px",
              fontFamily: "El Messiri",
            }}
          >
            Social Media Marketing{" "}
          </h3>

          <blockquote
            className="text-center"
            style={{
              fontSize: "18px",
              fontStyle: "italic",
              fontFamily: "El Messiri",
              color: "black",
            }}
          >
            Some areas we specialize in
          </blockquote>
          <div className="row col-sm-12">
            <div className="col-md-6 col-lg-3">
              <div className="card mb-5 " id="serve">
                <div className="card-body text-center ">
                  <a href="/web-development">
                    {" "}
                    <i className="material-icons">laptop</i>
                    <h5
                      className="card-title"
                      style={{ fontFamily: "El Messiri" }}
                    >
                      Web Development
                    </h5>{" "}
                  </a>
                  <p className="card-text" style={{ fontFamily: "El Messiri" }}>
                    Create an astonishing website with our expert design
                    services, tailored to exceed your satisfaction and captivate
                    your audience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card mb-5 " id="serve">
                <div className="card-body text-center">
                  <a href="/software/#mobile">
                    {" "}
                    <i className="material-icons">phone_iphone android</i>
                    <h5
                      className="card-title"
                      style={{ fontFamily: "El Messiri" }}
                    >
                      Mobile App Development{" "}
                    </h5>{" "}
                  </a>
                  <p className="card-text" style={{ fontFamily: "El Messiri" }}>
                    Craft an exceptional mobile app designed for small screens,
                    elevating user experience and driving substantial revenue
                    growth.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card mb-5 " id="serve">
                <div className="card-body text-center">
                  <a href="/growth">
                    {" "}
                    <i className="material-icons">mail_outline</i>
                    <h5
                      className="card-title"
                      style={{ fontFamily: "El Messiri" }}
                    >
                      Email Marketing
                    </h5>{" "}
                  </a>
                  <p className="card-text" style={{ fontFamily: "El Messiri" }}>
                    Elevate your business with impactful email marketing.
                    Cultivate strong relationships with your target audience
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card mb-5 " id="serve">
                <div className="card-body text-center">
                  <a href="/growth/#seo">
                    {" "}
                    <i className="material-icons">location_on</i>
                    <h5
                      className="card-title"
                      style={{ fontFamily: "El Messiri" }}
                    >
                      SEO
                    </h5>{" "}
                  </a>
                  <p className="card-text" style={{ fontFamily: "El Messiri" }}>
                    Search Engine Optimization strategically directs services
                    and information to targeted audiences, making it a crucial
                    marketing channel for businesses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="divider mt-5 mb-5"></div>
      <center>
        {" "}
        <i className="fa fa-briefcase dess"></i>{" "}
      </center>
      <h4 className="text-center oserve">Networking Services</h4>

      <div className="row">
        <div className="offset-1"></div>
        <div className=" row col-sm-11  container mt-5">
          <div
            className="col-md-4 bado " //animate__animated animate__fadeInUp
            // data-aos="fade-right"
            // data-aos-duration="2500"
          >
            <h4 style={{ fontFamily: "El Messiri" }}> Network Engineering </h4>
            <p style={{ fontFamily: "El Messiri" }}>
              {" "}
              {/* At CyberBrace System & Technology, we design and implement network
              configurations, troubleshoot performance issues, carry out network
              monitoring and configure security systems. */}
              At CyberBrace System & Technology, we expertly design networks,
              resolve performance issues, monitor networks, and fortify security
              systems for seamless operations.
            </p>
          </div>

          <div className="col-md-4" id="net">
            {/* <a href="/networkin"> */}
            <div
              className="card mb-5 " //animate__animated animate__fadeInDown
              id="cardo"
              // data-aos="fade-up"
              // data-aos-duration="2500"
            >
              <div className="card-body" style={{ height: "130px" }}>
                <i className="material-icons">build</i>
                <p className="card-text">
                  <li style={{ fontFamily: "El Messiri" }}>
                    network configurations
                  </li>
                  <li style={{ fontFamily: "El Messiri" }}>
                    network monitoring
                  </li>
                </p>
              </div>
            </div>
            {/* </a> */}
          </div>

          <div className="col-md-4" id="net">
            {/* <a href="/networking/#support"> */}
            <div
              className="card mb-5 " //animate__animated animate__fadeInDown
              id="cardo"
              // data-aos="fade-left"
              // data-aos-duration="2500"
            >
              <div className="card-body" style={{ height: "130px" }}>
                <i className="material-icons">security</i>
                <p className="card-text">
                  <li style={{ fontFamily: "El Messiri" }}>
                    Technical support
                  </li>
                  <li style={{ fontFamily: "El Messiri" }}>
                    troubleshoot performance issues
                  </li>
                </p>
              </div>
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
