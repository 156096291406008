import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure you import Bootstrap JS bundle

const CustomCarousel = () => {
  return (
    <div className="col-sm-12">
      <div
        id="home"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="5000"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#home"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#home"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#home"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#home"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#home"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100 animated fadeIn"
              src="images/keys.jpeg"
              alt="First slide"
            />
            <div className="carousel-caption d-md-block" id="carotext">
              <h2
                style={{
                  fontSize: "40px",
                  fontFamily: "El Messiri",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Software Development
              </h2>
              <h4
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "30px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Transforming software systems for enterprise growth
              </h4>
              {/* <a
                href="/software-development"
                className="btn btn-lg"
                style={{ fontFamily: "El Messiri" }}
              >
                Read More
              </a> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 animated fadeIn"
              src="images/support.png"
              alt="Second slide"
            />
            <div className="carousel-caption d-md-block" id="carotext">
              <h2
                style={{
                  fontSize: "40px",
                  fontFamily: "El Messiri",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Technical Support
              </h2>
              <h4
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "30px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                24/7 technical support for enterprises
              </h4>
              {/* <a
                href="/networking/#support"
                className="btn btn-lg"
                style={{ fontFamily: "El Messiri" }}
              >
                Read More
              </a> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 animated fadeIn"
              src="images/code1.jpeg"
              alt="Third slide"
            />
            <div className="carousel-caption d-md-block" id="carotext">
              <h2
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "40px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Web Development
              </h2>
              <h4
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "30px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Custom designs and implementation of websites for enterprises
              </h4>
              {/* <a
                href="/web-development"
                className="btn btn-lg"
                style={{ fontFamily: "El Messiri" }}
              >
                Read More
              </a> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 animated fadeIn"
              src="images/growth.jpg"
              alt="Fourth slide"
            />
            <div className="carousel-caption d-md-block" id="carotext">
              <h2
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "40px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Business Growth
              </h2>
              <h4
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "30px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Giving your business high exposure
              </h4>
              {/* <a
                href="/business-growth"
                className="btn btn-lg"
                style={{ fontFamily: "El Messiri" }}
              >
                Read More
              </a> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 animated fadeIn"
              src="images/network2.jpg"
              alt="Fifth slide"
            />
            <div className="carousel-caption d-md-block" id="carotext">
              <h2
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "40px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Network Engineering
              </h2>
              <h4
                style={{
                  fontFamily: "El Messiri",
                  fontSize: "30px",
                  textShadow: "5px 5px 5px black",
                }}
              >
                Delivering high-availability network infrastructure
              </h4>
              {/* <a
                href="/networking"
                className="btn btn-lg"
                style={{ fontFamily: "El Messiri" }}
              >
                Read More
              </a> */}
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#home"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#home"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CustomCarousel;
