import React from "react";
import { ReactComponent as ArrowTopRight } from "../../static/svg/arrow-top-right.svg";

const PreserveCard = ({ services }) => {
  return (
    <React.Fragment>
      {services.map((item, index) => (
        <div className="col-12 col-lg-4 preservCard" key={index}>
          <div className=" mt-4 mb-4 shadow-lg">{item.icon}</div>
          <h3 className=""> {item.label}</h3>

          <p>
            <ArrowTopRight />
          </p>
        </div>
      ))}
    </React.Fragment>
  );
};

export default PreserveCard;
