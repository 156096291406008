import React from "react";
import CustomCarousel from "../components/Index/CustomCarousel";
import Services from "../components/Index/Services";
import Team from "../components/Index/Team";
import Remarks from "../components/Index/Remarks";
import OurServices from "../components/Index/OurServices";

const Index = () => {
  return (
    <React.Fragment>
      <CustomCarousel />
      <OurServices />
      <Services />
      <Team />
      <Remarks />
    </React.Fragment>
  );
};

export default Index;
