import React from "react";
import { ReactComponent as DatabaseIcon } from "../../static/svg/database.svg";
import { ReactComponent as ArrowTopBtn } from "../../static/svg/arrow-top-btn.svg";
import { ReactComponent as NetworkIcon } from "../../static/svg/network.svg";
import { ReactComponent as BrandIcon } from "../../static/svg/brand.svg";
// import { ReactComponent as InteractIcon } from "../../static/svg/interact.svg";
import { ReactComponent as MobileIcon } from "../../static/svg/mobile.svg";
import { ReactComponent as CloudIcon } from "../../static/svg/cloud.svg";
import { ReactComponent as WebFlowIcon } from "../../static/svg/webflow.svg";
import { ReactComponent as WhatsappIcon } from "../../static/svg/whatsapp.svg";
import PreserveCard from "./PreserveCard";
import CBHeader from "./CBHeader";

const services = [
  { icon: <MobileIcon className=" iconSize" />, label: "Mobile App Design" },
  { icon: <NetworkIcon className=" iconSize" />, label: "Computer Networking" },
  {
    icon: <WebFlowIcon className=" iconSize" />,
    label: "Web Application Design",
  },
  { icon: <CloudIcon className=" iconSize" />, label: "Cloud Solutions" },
  { icon: <BrandIcon className=" iconSize" />, label: "Brand Identity" },
  {
    icon: <DatabaseIcon className=" iconSize" />,
    label: "Database Management",
  },
];

const OurServices = () => {
  return (
    <React.Fragment>
      <div className="container" id="services">
        {/* Custom Header */}
        <CBHeader
          header={"Our Services"}
          caption={`
            Efficiently empower with mobile apps, email marketing, cloud services, and more. Innovate across platforms for enhanced efficiency and versatility.
            `}
        />
        {/* <CBHeader
          header={"Our Team"}
          caption={`
            Meet our skilled tech team driving innovation, ensuring top-tier solutions, and delivering outstanding results for your business.
            `}
        />  */}
        {/* Custom Header Ends */}
      </div>

      <div className="container pb-5">
        <div className="row">
          <div className=" row col-12 col-lg-8 gap-4 gap-md-4 justify-content-center">
            <PreserveCard services={services} />

            {/* <div className="col preservCard">
              <div className=" mt-4 mb-4 shadow-lg">
                <UIUXIcon className=" iconSize" />
              </div>
              <h3 className="">UI/UX Design</h3>

              <p>
                <ArrowTopRight />
              </p>
            </div> */}
          </div>

          {/* Hire Card */}
          <div className="row col-lg-4 col-12 ms-lg-2 justify-content-center">
            <div className="col-12 hireCard">
              <p>
                <ArrowTopBtn />
              </p>
              <h3 className=" text-white">hire us</h3>
              <h4 className=" text-white">
                <strong>
                  <WhatsappIcon className=" whatSize" />
                  <a
                    href="https://wa.me/2347069367695?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about%20your%20services."
                    target="_blanc"
                  >
                    +234 706 936 7695
                  </a>{" "}
                </strong>
                <br />
                <strong>
                  <WhatsappIcon className=" whatSize" />
                  <a
                    href="https://wa.me/2347069367646?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about%20your%20services."
                    target="_blanc"
                  >
                    +234 706 936 7646
                  </a>{" "}
                </strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurServices;
