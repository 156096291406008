import React from "react";

const CBHeader = ({ header, caption }) => {
  return (
    <React.Fragment>
      <div className="row my-5">
        <div className="col-12 col-lg-6 text-center text-lg-start start-0">
          {/* <p>Our Service</p> */}
          <h1>{header}</h1>
        </div>
        <div className="col-12 col-lg-6 text-center text-lg-end end-0 text-opacity-50">
          <p>{caption}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CBHeader;
