import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/global/Layout";
import Index from "./pages/Index";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Index />} />
            {/* <Route path="/" element={<Note />} /> */}
          </Routes>
        </Layout>
      </Router>
    </React.Fragment>
  );
}

export default App;
