import React from "react";
// import CBHeader from "../Index/CBHeader";
import { ReactComponent as PhoneIcon } from "../../static/svg/phone.svg";
import { ReactComponent as PinIcon } from "../../static/svg/pin.svg";
import { ReactComponent as MailIcon } from "../../static/svg/mail.svg";
import { ReactComponent as FacebookIcon } from "../../static/svg/facebook-round.svg";
import { ReactComponent as InstagramIcon } from "../../static/svg/instagram-round.svg";
import { ReactComponent as LinkedInIcon } from "../../static/svg/linkedin-rounded.svg";
import { ReactComponent as TwitterIcon } from "../../static/svg/twitter-boxed.svg";
import { ReactComponent as ArrowTopBtn } from "../../static/svg/arrow-top-btn.svg";

const Footer = () => {
  // let navigate = useNavigate();

  const handleClick = (url) => {
    window.open(url);
  };
  return (
    <React.Fragment>
      {/* <div className="container" id="contact">
        <CBHeader
          header={"Get In Touch"}
          caption={`
            Reach out today and let's start a conversation about how we can help you achieve your goals with tailored solutions.
            `}
        />
      </div> */}
      <div className="contFoot" id="contact">
        {/* <div className="row py-5 px-5">
          <div className="col-12 col-lg-6 text-white text-center text-lg-start start-0">
            <h1 className="messi">Get In Touch</h1>
          </div>
          <div className="col-12 col-lg-6 text-white text-center text-lg-end end-0 text-opacity-50">
            <p>
              Reach out today and let's start a conversation about how we can
              help you achieve your goals with tailored solutions.
            </p>
          </div>
        </div> */}

        <div className="pt-5 row">
          <div className="col-lg-4 col-12 letsWork">
            <h1>Lets work together</h1>
            <p className="">
              Based in Lagos Nigeria
              <TwitterIcon
                className="social"
                onClick={() => handleClick("http://twitter.com/cyberbrace1")}
              />
              <InstagramIcon
                className="social"
                onClick={() =>
                  handleClick("https://www.instagram.com/cyberbrace/")
                }
              />
              <FacebookIcon className="social" />
              <LinkedInIcon className="social" />
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            {/* <h1>GET IN TOUCH</h1> */}

            <div className="conty">
              <PhoneIcon className=" roundIcon" />
              <div className="pt-3">
                <strong className="">Phone</strong>
                <p className="">
                  +234 706 936 7695 <br />
                  +234 706 936 7646
                </p>
              </div>
            </div>

            <div className="conty">
              <PinIcon className=" roundIcon" />
              <div className="pt-3">
                <strong className="">Address</strong>
                <p className="">Lekki, Lagos</p>
              </div>
            </div>

            <div className="conty">
              <MailIcon className=" roundIcon" />
              <div className="pt-3">
                <strong className="">Email</strong>
                <p className="">
                  cyberbrace16@gmail.com <br />
                  cyberbracetech@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="col-12 col-lg-4 contCard">
              <h3 className="">
                {" "}
                Looking for a talented designer and skilled engineer to provide
                top-notch software and cloud solutions??
              </h3>
              <h4 className=""> cyberbracetech@gmail.com</h4>

              <p>
                <ArrowTopBtn className="newSize" />
              </p>
            </div>
          </div>
        </div>

        <div className="footImg">
          <img src="images/cybe.png" alt="" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
