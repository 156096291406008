import React from "react";
import CBHeader from "./CBHeader";
import Testimony from "./Testimony";
// import Testimony from "./Testimony";

const remarks = [
  {
    img: "images/jibson.jpg",
    client: "Mr Jibade Adebayo",
    company: "CEO - Jibeal Unique Company Ltd",
    text: `The app you designed perfectly meets my needs. It's significantly boosting my business growth every day. Keep up the excellent work!`,
  },
  {
    img: "images/avatar.png",
    client: "Mr Tobi",
    company: "CEO - Prime Direct Multi Global Service Ltd",
    text: `The custom-made app perfectly fits my business needs and has significantly boosted its growth. Thanks for a job well done!`,
  },
  {
    img: "images/jibson.jpg",
    client: "Mr Jibade Adebayo",
    company: "CEO - Jibeal Unique Company Ltd",
    text: `The app you designed perfectly meets my needs. It's significantly boosting my business growth every day. Keep up the excellent work!`,
  },
  {
    img: "images/avatar.png",
    client: "Mr Tobi",
    company: "CEO - Prime Direct Multi Global Service Ltd",
    text: `The custom-made app perfectly fits my business needs and has significantly boosted its growth. Thanks for a job well done!`,
  },
];

const Remarks = () => {
  return (
    <React.Fragment>
      <div className="container pt-3 pb-3" id="remark">
        {/* Custom Header */}
        <CBHeader
          header={"Our Testimonials"}
          caption={`
            Hear from our satisfied clients about our exceptional service, innovative solutions, and the positive impact on their businesses.
            `}
        />
        {/* Custom Header Ends */}
      </div>

      <div className="container remake">
        <div className="row gap-5 justify-content-center">
          <Testimony remarks={remarks} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Remarks;
